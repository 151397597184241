<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="过户列表" name="1"></el-tab-pane>
      <el-tab-pane label="销户列表" name="0"></el-tab-pane>
    </el-tabs>
    <table-template
      :table_data="table_data"
      :isloading="isloading"
      :formatList="['isdelete']"
    >
      <template slot="isdelete" slot-scope="scope">
        {{
          scope.scope.row.isdelete == 2 || scope.scope.row.isdelete == -1
            ? "已处理"
            : "未处理"
        }}
      </template>
      <template slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          @click="showDetail(scope.scope.row)"
          >详情</el-button
        >
        <el-popconfirm
          title="确认删除该条数据？"
          @confirm="delData(scope.scope.row.id)"
        >
          <el-button
            type="danger"
            slot="reference"
            size="small"
            style="margin-left: 10px"
            >删除</el-button
          >
        </el-popconfirm>
      </template>
    </table-template>
    <div style="text-align: right">
      <base-pageination
        :pageinfo="table_data.pageinfo"
        @handlePageClick="handlePageClick"
      ></base-pageination>
    </div>
    <audit-detail ref="auditDetail"></audit-detail>
  </div>
</template>

<script>
import BasePageination from "../components/BasePageination.vue";
import ListConditionForm from "../components/ListConditionForm.vue";
import TableTemplate from "../components/TableTemplate.vue";
import AuditDetail from "../components/AuditDetail.vue";

import { getPlUserList, updatePlU } from "@/api/message.js";
import { delInfo } from "@/api/audit.js";
export default {
  components: {
    ListConditionForm,
    TableTemplate,
    BasePageination,
    AuditDetail,
  },
  data() {
    return {
      table_data: {
        tableConfig: [
          { key: "linkman", name: "用户姓名" },
          { key: "phone", name: "联系方式" },
          { key: "address", name: "地址" },
          { key: "conNo", name: "燃气户号" },
          { key: "isdelete", name: "状态" },

          { key: "contractpath", name: "合同地址" },
          { key: "createtime", name: "提交时间" },
          { key: "updatetime", name: "更新时间" },
        ],
        data: [],
        pageinfo: { currentpage: 1, pageSize: 10, total: 0 },
      },
      typeList: ["报修", "咨询", "建议", "投诉", "表扬", "满意度调查"],
      isloading: false,
      activeName: "1",
      platformtype: 1,
    };
  },
  mounted() {
    if (localStorage.getItem("username") == "dingyuan") this.platformtype = 2;
    else if (localStorage.getItem("username") == "admin") this.platformtype = 1;
    this.handlePageClick(1);
  },
  methods: {
    handleClick() {
      if (this.activeName == 1) {
        if (this.table_data.tableConfig.length == 7) {
          this.table_data.tableConfig.push({
            key: "newlinkman",
            name: "新用户姓名",
          });
          this.table_data.tableConfig.push({
            key: "newphone",
            name: "联系方式",
          });
        }
      } else {
        this.table_data.tableConfig = this.table_data.tableConfig.slice(0, 7);
      }
      this.handlePageClick(1);
    },
    fetchData() {
      this.isloading = true;
      getPlUserList({
        pageSize: this.table_data.pageinfo.pageSize,
        pageNum: this.table_data.pageinfo.currentpage,
        isdelete: this.activeName,
        platformtype: this.platformtype,
      }).then((res) => {
        this.isloading = false;
        const { code, data, page } = res;
        if (code === 10000) {
          this.table_data.data = data;
          this.table_data.pageinfo = page;
        }
      });
    },
    handlePageClick(page) {
      this.table_data.pageinfo.currentpage = page;
      this.fetchData();
    },
    showDetail(obj) {
      this.$refs.auditDetail.setDialogShow(obj, "gxh_id", this.activeName);
    },
    async delData(id) {
      const { code } = await delInfo({ id, type: 3 });
      if (code === 10000) this.fetchData();
      else this.$message.warning("操作失败！");
    },
    updateInfo(obj) {
      this.$confirm("确认此条信息是否已处理, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        updatePlU({ id: obj.id, isdelete: this.activeName == 1 ? 2 : -1 }).then(
          (res) => {
            const { code } = res;
            if (code === 10000) {
              this.$message.success("操作成功！");
              this.handlePageClick(1);
            } else {
              this.$message.success("操作失败！");
            }
          }
        );
      });
    },
  },
};
</script>

<style></style>
